<!-- 新增就居民点单 -->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 基本信息 -->
      <ax-form ref="form" :formBuilder="formBuilder" @change="typeChange">
        <!-- 以下方法是给input添加校验的方法 -->
        <div slot="residentName" slot-scope="{ record }" style="display: flex">
          <a-input
            disabled
            v-decorator="[
              record.model,
              {
                rules: record.rules,
              },
            ]"
            placeholder="请输入"
          ></a-input>
          <ax-button @click="choosePeople" style="margin-left: 2%"
            >选择人员</ax-button
          >
        </div>
        <div
          slot="fileIds"
          slot-scope="{ record }"
          v-decorator="[
            record.model,
            {
              rules: record.rules,
            },
          ]"
        >
          <imageUpload ref="img1"></imageUpload>
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @choose="receiveVolunteerId"
      :selectedPeopleList="selectedPeopleList"
    ></choose-people>
  </div>
</template>
  <script>
const formList = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入活动名称" }],
  },

  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { placeholder: "请选择人员", disabled: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        message: "身份证号不能为空",
      },
    ],
  },
  {
    label: "性别",
    type: "",
    model: "gender",
    options: { placeholder: "请选择人员", disabled: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        message: "性别不能为空",
      },
    ],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: { placeholder: "请选择人员", disabled: true },
    col: { span: 12 },
    rules: [{ required: true, message: "年龄不能为空" }],
  },
  {
    label: "联系电话", //自动获取可修改
    type: "input",
    model: "residentPhone",
    options: {
      placeholder: "请输入联系电话",
      maxLength: 11,
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "所属网格", //自动获取
    type: "",
    model: "gridName",
    options: {
      placeholder: "所属网格",
      disabled: true,
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择所属网格" }],
  },
  {
    label: "服务地点",
    type: "input",
    model: "taskLocation",
    options: {
      placeholder: "服务地点",
      maxLength: 50,
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入服务地点" }],
  },
  {
    label: "点单类型",
    type: "treeSelect",
    model: "taskTypeId",
    options: { placeholder: "请选择点单类型" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择点单类型" }],
  },
  {
    label: "设置积分",
    type: "inputNumber",
    model: "integralScore",
    options: { placeholder: "请设置积分", max: 99999, min: 0, precision: 0 },
    col: { span: 12 },
    rules: [{ required: false, message: "请设置积分" }],
  },
  {
    label: "需求内容",
    type: "textarea",
    model: "taskDescription",
    options: { placeholder: "需求内容", minRows: 3, maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "需求内容不能为空" }],
  },
  {
    label: "图片",
    type: "",
    model: "fileIds",
    options: {},
    col: { span: 24 },
    rules: [{ required: false, message: "请上传图片" }],
  },
];
import { min } from "moment";
import api from "./api";
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";

import choosePeople from "./choosePeople.vue";
export default {
  components: {
    imageUpload,
    choosePeople,
  },
  data() {
    return {
      visible: false,
      businessId: "",
      id: "",
      title: "",
      selectLable: "",
      noChoice: false,
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      selectedPeopleList: [],
      nameId: "", //姓名id
      name: "", //姓名
      gridName: "",
      pid: "", //分组id
      typeName: "", //分组名称
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.noChoice = true;
      this.$refs.choosePeople.openModal({ title: "选择人员" });
    },
    typeChange(e, type) {
      if (type === "taskTypeId") {
        // console.log(e, type);
        // // this.selectLable = type[0];
      }
    },
    // 接收子组件传过来的peopleId
    receiveVolunteerId(item) {
      console.log(item, "185");
      if (item.id) {
        this.$refs.form.setFieldsValue({
          residentName: item.residentName,
          identityCard: item.identityCard,
          gender: item.gender,
          age: item.age,
          residentPhone: item.phone,
          gridName: item.gridName,
        });
        this.residentId = item.id;
        this.gridId = item.gridId;
      }
    },
    // 获取点单类型树形数据
    async getTypeTreeList() {
      try {
        const res = await api.getTypeTreeList();
        let treeData = res.data.map((res) => {
          return {
            label: res.typeName,
            key: res.id,
            value: res.id,
            grade: res.grade,
            disabled: res.grade === "0" ? true : false,
            children: this.mapChildren(res.children),
          };
        });

        console.log(treeData, "256");

        if (!res.data.length) {
          this.$refs.form.setFormItemProp("taskTypeId", {
            options: {
              disabled: true,
              placeholder: "点单类型",
            },
          });
        } else {
          this.$refs.form.setFormItemProp("taskTypeId", {
            options: {
              treeData,
              allowClear: true,
              placeholder: "点单类型",
            },
            rules: [{ required: true, message: "点单类型" }],
          });
        }
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.typeName,
        key: child.id,
        value: child.id,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.saveType = false;
      this.getTypeTreeList();
      this.title = record.title;
      this.selectedPeopleList = record.selectedPeopleList;
    },
    // 关闭弹窗
    closeModal() {
      this.$refs.form.resetFields();
      this.$refs.img1.removeFileList();
      this.visible = false;
      this.$emit("refsh");
    },
    // 提交
    async onSubmit() {
      if (!this.noChoice) {
        this.$message.warning("请先选择人员");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        this.fileIds = [];
        const files = this.$refs.img1.getFile();
        const files1 = this.$refs.img1.getFileListAll();
        console.log(files, "185");
        if (files1.length > 0) {
          const ids = files1.map((item) => {
            return item.uid;
          });
          const res = await api.del(ids.join(","));
          console.log(res, "871");
          console.log(files1, "369");
        }
        files.map((item) => {
          if (item.response) {
            this.fileIds.push(item.response.data.id);
          } else {
            this.fileIds.push(item.uid);
          }
        });
        value.fileIds = [...new Set([...this.fileIds])];
        const obj = {
          residentId: this.residentId,
          residentPhone: value.residentPhone,
          gridId: this.gridId,
          integralScore: value.integralScore || 0,
          taskLocation: value.taskLocation,
          taskTypeId: value.taskTypeId,
          fileIds: value.fileIds,
          taskDescription: value.taskDescription,
          taskStatus: "1",
          orderTime: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        };
        console.log(obj, "新增提交数据");
        try {
          const res = await api.addRecord(obj);
          console.log(res);
          if (res.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            // 提交成功后关闭弹窗
            this.closeModal();
          } else {
            this.$message.error("新增失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(err);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
/deep/.el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
/deep/.el-range-editor.el-input__inner {
  width: 100%;
  padding: 0 15px;
}
.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>