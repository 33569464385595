var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"z-index":10,"title":_vm.title,"visible":_vm.visible,"width":"60%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.closeModal}},[_c('ax-form',{ref:"form",attrs:{"formBuilder":_vm.formBuilder},on:{"change":_vm.typeChange},scopedSlots:_vm._u([{key:"residentName",fn:function({ record }){return _c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            record.model,
            {
              rules: record.rules,
            },
          ]),expression:"[\n            record.model,\n            {\n              rules: record.rules,\n            },\n          ]"}],attrs:{"disabled":"","placeholder":"请输入"}}),_c('ax-button',{staticStyle:{"margin-left":"2%"},on:{"click":_vm.choosePeople}},[_vm._v("选择人员")])],1)}},{key:"fileIds",fn:function({ record }){return _c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          record.model,
          {
            rules: record.rules,
          },
        ]),expression:"[\n          record.model,\n          {\n            rules: record.rules,\n          },\n        ]"}]},[_c('imageUpload',{ref:"img1"})],1)}}])}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"background-color":"#556bff"},attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v("保 存")]),_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")])],1)],1),_c('choose-people',{ref:"choosePeople",attrs:{"selectedPeopleList":_vm.selectedPeopleList},on:{"choose":_vm.receiveVolunteerId}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }